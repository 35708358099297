import '../scss/forBS5.scss';

// eslint-disable-next-line no-unused-vars
import { Dropdown } from 'bootstrap';

// blog posts added in the backend!
// on page load
document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM loaded');
});
